import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layout/Layout"
import Container from "../../../components/layout/Container"
import Type from "../../../components/typography/Type"
import SearchNav from "../../../components/search/SearchNav"
import JourneyCard from "../../../components/cards/JourneyCard"
import ScreenCard from "../../../components/cards/ScreenCard"

import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";


import { Router } from "@reach/router"
import { getUser, isLoggedIn } from "../../../services/auth"
import LoginPrompt from "../../../components/app/loginPrompt"




export default function Pages( {data} ) {
return (
  <Layout>
  <Container>
  
  {isLoggedIn() ? (
  <>
  
  <div className="flex flex-col lg:flex-row lg:space-x-16">
<div className="w-full lg:w-3/12">
 <SearchNav industry={data.airtable.data.Path}/>
</div>
<div className="w-full lg:w-9/12 mt-12 lg:mt-0">
<Type type="h3">{data.airtable.data.Name} journeys</Type>

  <div className="grid grid-cols-1 gap-8">
  
{data.airtable.data.Journeys.map((array) => (

  <div className="mt-10 w-full">

  <Type type="h4">{array.data.JourneyName}</Type>
    


  <Swiper
  style={{
    "--swiper-navigation-color": "#000",
    "--swiper-pagination-color": "#000",
  }}
    // install Swiper modules\
    modules={[Navigation]}
    slidesPerView={4}
    spaceBetween={30}
    loop={false}
    navigation
    onSwiper={(swiper) => console.log(swiper)}
    onSlideChange={() => console.log('slide change')}
    className={array.data.Path}
  >
  {array.data.Screens.map((screenArray) => (
    <SwiperSlide> 
  <ScreenCard
    url={screenArray.data.Path}
    image={screenArray.data.ThumbS3}
    title={screenArray.data.Name}
    />
    </SwiperSlide>
  ))}
  </Swiper>
  
  </div>
))}

  </div>
</div>
  </div>
  
  </>
  ) : (
    <>
      <LoginPrompt/>
    </>
  )}
  

  
  </Container>
  </Layout>
  )
}



export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "Organisations" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
        Journeys {
          data {
            JourneyName
            Path
            ScreenshotURLHero
            OrganisationTxt
            Screens {
              data {
                Name
                Path
                ThumbS3
                
              }
            }
          }
        }
      }
    }
  }
`
